/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css */
/* @import "tailwindcss/base"; */
/* @import "tailwindcss/components"; */
/* @import "tailwindcss/utilities"; */
/* @import "@daisyui/full.css"; */

/* Add this to your CSS file */
.custom-quill-editor .ql-editor {
    min-height: auto !important;
    height: auto !important;
    padding: 0 !important;
    white-space: pre-wrap;
  }
  
  .chat-wrap.MuiBox-root, 
code.word-break-all, span.word-break-all, div.word-break-all {
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
  }

  span.text-span, div.text-span {
    white-space: pre-wrap;
  }
  span.pre-wrap, div.pre-wrap {
    white-space: pre-wrap;
  }
/* 
  .copy-pre {
    margin-bottom: 3em !important;
    -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 35px -23px 2px -16px rgba(0, 0, 0, 0);
    box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 35px -23px 2px -16px rgba(0, 0, 0, 0);
    position: relative;
} */

.copy-pre {
  margin-bottom: 3em !important;
  -webkit-box-shadow: -8px 0px 13px -7px #000000, 8px 0px 13px -7px #000000, 25px -18px 2px -16px rgba(0, 0, 0, 0);
  box-shadow: -8px 0px 13px -7px #000000, 8px 0px 13px -7px #000000, 25px -18px 2px -16px rgba(0, 0, 0, 0);
  position: relative;
  border-radius: .4rem;
  /* padding: 8px; */
}

.code-copy-btn {
  color: white;
  position: absolute;
  right: 10px;
  top: -2px;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.code-copy-btn:hover {
  transform: scale(1.1);
  opacity: 0.9;
}

.text-white {
  color: white !important;
}
.back-white {
  background-color: white !important;
}
.generic-code{
  /* padding: 100px; */
  /* padding: 50px !important; */
}

.pre-container {
  /* padding: 10px; */
  overflow: hidden;
}
.markdown-text {
    /* background should be like dark code */
    background-color: rgb(40, 44, 52);
    color: rgb(171, 178, 191);
    text-shadow: rgb(0 0 0 / 30%) 0px 1px;
    font-family: "Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    /* line-height: 1.5; */
    /* tab-size: 2; */
    /* hyphens: none; */
    padding: .1em;
    /* margin: 0.5em 0px; */
    /* overflow: auto; */
    border-radius: 0.2em;

}

.center span.MuiButton-endIcon
{
    margin: 0px;

}

.MuiTypography-h8 {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: .9rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  cursor: pointer;
}

.lexara{
  font-family: "Lexend Deca", sans-serif;
  /* italics */
  font-style: normal;
  /* weight */
  font-weight: 400;
  /* size */
  font-size: 1.5rem;
  /* line height */
  line-height: 1.5;
  /* letter spacing */
  letter-spacing: 0em;
  /* text transform */
  text-transform: none;
  /* text decoration */
  text-decoration: none;
  /* text align */
  text-align: left;
  /* color */
  /* color: #000000; */

}

/* Reset the styles for the react-pdf page content to fix the issue */
.pdf-viewer .react-pdf__Page__canvas {
  position: relative !important;
}

.pdf-viewer .react-pdf__Page__textContent {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}